/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;
  line-height: $base-line-height * $base-font-size * 2.25;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.5);
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  display: flex;
  align-items: center;

  @include media-query($on-palm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }

  img {
    max-width: 50px;
    height: 24px;
    width: 24px;
    margin: 0.2em;
    margin-right: 0.6em;
  }
}

.site-nav {
  position: absolute;
  top: 9px;
  right: $spacing-unit / 2;
  background-color: $background-color;
  border: 1px solid $grey-color-light;
  border-radius: 5px;
  text-align: right;

  .nav-trigger {
      display: none;
  }
  label[for="nav-trigger"] {
    margin-bottom: 0px;
  }

  .menu-icon {
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;

    > svg path {
      fill: $grey-color-dark;
    }
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 1rem;
    background-color: transparent;
    &:hover {
      background-color: #e9ecef;
    }
  }

  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
    }
  }
}

/**
 * Search banner
 */
.search-banner {
  width: 100%;
  &.search-banner--complete {
    background-color: #0088ED;
    padding: 3rem 0 2rem 0;

    h1 {
      display: block;
    }
  }
  h1 {
    padding: 2rem 0.2rem;
    color: white;
    display: none;
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width:         calc(100% - (#{$spacing-unit} / 2));
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1,
.footer-col-2 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width:         calc(100% - (#{$spacing-unit} / 2));
}

@media screen and (min-width: $on-large) {
  .footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
  }

  .footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
  }
}

@media screen and (min-width: $on-medium) {
  .footer-col {
    float: left;
  }
}



/**
 * Page content
 */
.page-content {
  padding: 0;
  flex: 1 0 auto;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
  @include relative-font-size(2.25);
  letter-spacing: -1px;
  line-height: 1;
  font-weight: bold;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;
  padding-top: $spacing-unit;

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h4 {
    @include relative-font-size(1.125);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.25);
    }
  }
}


.social-media-list {
  display: table;
  margin: 0 auto;
  li {
    float: left;
    margin: 0 5px;
    &:first-of-type { margin-left: 0 }
    &:last-of-type { margin-right: 0 }
    a {
      display: block;
      padding: $spacing-unit / 4;
      border: 1px solid $grey-color-light
    }
    &:hover .svg-icon { fill: currentColor; }
  }
}


/**
 * Grid helpers
 */
@media screen and (min-width: $on-large) {
  .one-half {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }
}

/**
For the device table
*/
.container {
    width: 100%;
    margin: auto;
    padding: 10px;
}
.left {
    width: 50%;
    float: left;

}
.right {
    width: 50%;
    margin-left: 50%;
}

.rightcol { float: right; }
@media (max-width: 600px) {
   .rightcol { float: none; }
}
.table tbody+tbody {
  border-top: none;
}

/* NAVIGATION BAR */

.trigger {
  .page-link {
    border-style: unset !important;
  }

} 