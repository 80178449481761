@font-face {
  font-family: 'century-gothic';
  font-weight: normal;
  src: url("/assets/fonts/century-gothic/CenturyGothic.ttf") format("truetype");
}

@font-face {
  font-family: 'century-gothic';
  font-weight: bold;
  src: url("/assets/fonts/century-gothic/GOTHICB.TTF") format("truetype")
}

* {
  font-family: 'century-gothic', 'sans-serif';
}

h1, .h1 {
  font-size: 2.25rem;
}

h1, h2, h3 {
  padding: 0;
  margin-top: 0;
  font-weight: 700;
}

.full-width-div {

  width: 100%;
  left: 0;
  overflow-x: hidden !important;

}

.row {
  margin-top: 5px;
}

@import
  "minima/initialize";
.row-detail:nth-of-type(even) {
  background: $grey-color-100;
}
.row-detail:nth-of-type(odd) {
  background: $grey-color-50;
}

.hidden {
  display: none;
}

.site-nav {
  z-index: 1;
}

input-group mb-3 {
  margin-top: 20px;
}


table {
  display: block;
  width: 100%;

}

thead th {
  position: sticky;
  top: 0;
}

.laptop-table {
  display: block;
  height: 200px;
  overflow-y: scroll;

}


.mycontainer {
  width: 100%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.col-sm-8 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.col-sm-4 {
  margin-top: 5px;
  margin-bottom: 5px;
}


.rLabel {
  margin-left: 5px;
  vertical-align: middle;

}

#btnReset {
  font-weight: bold;
  cursor: pointer;
  color: #2a7ae2;
}

#myInput {
  margin-left: 15px;
  width: 50%;
  height: 25px;
}

#pSearch {
  font-weight: bold;
  margin-top: 10px;
  margin-left: 15px;

}

/* New Device info */
#container-deviceinfo {
  max-width: 100% !important;
  overflow-x: hidden;

}

.dt-left, .dt-right {
  font-size: 1rem;
}

.dd-right ul {
  margin-left: 15px;
  margin-bottom: 0;
}

dd, dt {
  margin-bottom: 0.5rem;
}

.device-label-left {
  font-weight: bold;
  text-align: left;
}

.span-boot {
  display: block;
  word-wrap: break-word;
}

kbd {
  white-space: nowrap ;
}

/* New Device info */

.ul-install {
  margin-left: 10px;
}

ul.list-unstyled,
ol.list-unstyled {
  margin-left: 0;
}

.jobs-table th:first-child {
  width: 25%;
}

@import
  "minima/skins/classic";

.flex {
  display: flex;
}

.chip {
  color: black;
  background-color: $grey-color-light;
  font-weight: bold;
  padding: 0.1rem 0.6rem;
  border-radius: 1rem;
  display: inline-flex;
  font-size: 0.8rem;
}

.search-form {
  margin: 3rem 0;
}
.search-results-message {
  margin-bottom: 2.75rem;
  margin-left: 4px;
  font-size: 0.875rem;
}
.search-input-container {
  width: 100%;
  padding: 0.5rem;
  padding-left: 16px;
  border: 1px solid #C6C6C6;
  background-color: white;
  border-radius: 50px;
  outline: none;
  display: flex;
  input {
    border: none;
    flex-grow: 1;
    outline: none;
  }
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, .13);
  }
  button {
    position: relative;
    height: 100%;
    width: 38px;
    min-width: 38px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon {
      transition: fill .2s ease-in-out, transform .1s ease-in-out;
    }
    &:hover .icon {
      fill: #5068dd;
      transform: scale(1.2);
    }
  }
}
.search-results {}
.search-result {
  margin-bottom: 32px;
}
.search-result__link:hover,
.search-result__link:visited {
  text-decoration: none;
  color: inherit;
}
.search-result__title {
  font-size: 1rem;
  color: #1f6ed7;
  margin-bottom: 6px;
  font-weight: bold;
}
.search-result__body {
  font-size: 1rem;
  color: #60686f;
  margin-top: 8px;
  margin-bottom: 6px;
  em {
    background-color: yellow;
  }
}
.search-result__footer .search-result__link {
  color: #39a441;
  font-size: 14px;
}
.bg-blue {
  background-color: #007fffff;
  border-color: #007fffff;
  &:hover {
    background-color: #24527EFF;
    border-color: #24527EFF;
  }
}
a.btn-secondary {
  color: white;
}
.loading-icon {
  display: none;
  justify-content: center;
  margin-top: 25vh;


  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: black;
    margin: 0.2rem;
  }

  .line:nth-last-child(1) {
    animation: loadingDots 0.75s 0.1s linear infinite;
  }
  .line:nth-last-child(2) {
    animation: loadingDots 0.75s 0.2s linear infinite;
  }
  .line:nth-last-child(3) {
    animation: loadingDots 0.75s 0.3s linear infinite;
  }
}
.no-results-message {
  display: none;
  justify-content: center;
  margin-top: 20vh;
  color: #707070;
  font-size: $small-font-size;
}
.product-title {
  font-size: 36px;
}
.smartphone-table {
  border: none;
  sup {
    position: static;
    vertical-align: super;
  }
  thead {
    background-color: $grey-color-100;
  }
  tbody tr:nth-child(even) {
    background-color: $grey-color-50;
  }
  @media screen and (max-width: $on-large) {
    overflow-x: auto;
  }
}
@keyframes loadingDots {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.tt-input {
  width: 100%;
}
.tt-menu {
  font-size: 16px;
}
.post-content img
{
	border: 1px solid #dbdbdb;
    margin: 2px 0;
}
[aria-expanded="false"]:after{
  content: "";
  background-image: url(../images/sort_asc.png);
  height: 10px;
  width: 20px;
  display: inline-block;
}
[aria-expanded="true"]:after{
  content: "";
  background-image: url(../images/sort_desc.png);
  height: 20px;
  width: 20px;
  display: inline-block;
}
#device-filters:hover{
  color: #2a7ae2;
}
.referral-banner{
  display: inline-flex;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 16px 2%;
}
.referral-banner:before {
  content: "";
  background-image: url(../images/warning.svg);
  width: 1.5em;
  height: 1.5em;
  margin-right: 1em;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: $on-medium) {
  .referral-banner:before{
    width: 2em;
    height: 2em;
  }
}